<template>
  <div>
    <div style="text-align: right;margin-right: 10%;margin-bottom: 2%;">
      <el-button type="primary" @click="addUser()">添加用户</el-button>
    </div>
    <div class="article-box">
      <el-table :data="data" style="width: 100%" v-loading="loading">
        <el-table-column prop="GUserID" label="用户ID" align="center"></el-table-column>
        <el-table-column prop="GuaName" label="用户昵称" align="center"></el-table-column>
        <el-table-column prop="HeadImageUrl" label="用户头像" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.HeadImageUrl" width="100px" height="100px">
          </template>
        </el-table-column>
        <el-table-column prop="RegisterMobile" label="用户手机号" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加用户" :visible.sync="dialogVisible" width="30%" :before-close="close">
      <p>用户昵称：
        <el-input v-model="form.name" placeholder="请输入昵称" style="width: 300px;"></el-input>
      </p>
      <el-upload class="upload-demo" ref="upload" :action="url" :on-success="success" :on-error="error"
        :file-list="fileList" :on-progress="uploading" :headers="{'token':token}" :limit="1" list-type="picture"
        style="width: 200px;height: 200px;margin-left: 30%;">
        <el-button size="small" type="primary">上传头像</el-button>
      </el-upload>
      <span slot="footer">
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    setUserList,
    setUser
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        token: localStorage.getItem('Token'), //token
        url: this.$url + '/upload/img', //上传头像请求地址
        data: [], // 用户列表
        loading: false, //加载框
        dialogVisible: false, //遮罩层
        fileList: [], //上传头像列表
        form: {}, //用户
        currpage: 1, // 初始页
        pagesize: 5, // 每页显示条目个数
        CountNum: 0 // 总条数
      }
    },
    mounted() {
      this.setUserList(1) // 获取第一页用户列表
    },
    methods: {
      addUser() { //点击添加用户弹窗
        this.dialogVisible = true
        this.form = {}
        this.fileList = []
      },
      close(done) { //关闭弹窗清空数据
        this.form = {}
        this.fileList = []
        done()
      },
      success(response) { //头像上传成功
        this.form.imgUrl = response.data.url
        console.log(this.form.imgUrl)
      },
      uploading(event, file, fileList) { //判断用户上传文件类型
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          this.$message.success("上传头像成功！")
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式头像，请重新上传")
          this.$refs.upload.clearFiles() //清除上传的文件
          this.$refs.upload.abort() //取消上传
        }
      },
      error(err) { //头像上传失败
        console.log(err)
      },
      setUserList(currpage) { // 用户列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        setUserList(data).then(res => {
          this.data = res.data.List // 用户列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      add() { //添加用户
        let data = {}
        data["GuaName"] = this.form.name
        data["HeadImageUrl"] = this.form.imgUrl
        if (!this.form.name) {
          this.$message.warning("请输入昵称")
        } else if (!this.form.imgUrl) {
          this.$message.warning("请选择头像")
        } else {
          setUser(data).then(res => {
            if (res.status.code == 1) {
              this.$message.success("用户添加成功")
              this.dialogVisible = false
              this.form = {}
              this.setUserList(1)
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      handleCurrentChange(val) { // 分页
        this.setUserList(val)
        this.currpage = val
      }
    }
  }
</script>

<style>
</style>
